import {
    COUPON_LIST,
    DELETE_ENQUIRY
   } from "../actions/types";
   
   const initialState = {
    couponlist: null
   };
   
   const couponReducer = (state = initialState, action) => {
     switch (action.type) {
       case COUPON_LIST:
         return {
           ...state,
           couponlist: action.payload,
         };
     
      
       default:
         return state;
     }
   };
   export default couponReducer;
   