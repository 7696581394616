import { combineReducers } from "redux";
import sideBarReducer from "./sideBarReducer";
import loginReducer from "./loginReducer";
import addUserReducer from "./addUserReducer";
import commonReducer from "./commonReducer"
import productReducer from "./productReducer"
import storeReducer from "./storeReducer"
import orderReducer from "./orderReducer"
import productCategoryReducer from "./productCategoryReducer"
import productReviewReducer from "./productReviewReducer"
import favoriteProductReducer from "./favoriteProductReducer"
import BannerReducer from "./bannerReducer"
import faqReducer from "./faqReducer"
import enquiryReducer from "./enquiryReducer"
import riderReducer from "./riderReducer"
import couponReducer from "./couponReducer"

import errorResponse from "./errorReducer";

const rootReducer = combineReducers({
  sideBarResponse: sideBarReducer,
  loginResponse: loginReducer,
  addUserResponse:addUserReducer,
  commonResponse: commonReducer,
  productResponse: productReducer,
  storeResponse: storeReducer,
  orderResponse:orderReducer,
  productCatResponse:productCategoryReducer,
  productReviewResponse:productReviewReducer,
  favProductResponse :favoriteProductReducer,
  riderResponse: riderReducer,
  bannerResponse: BannerReducer,
  faqResponse: faqReducer,
  enquiryResponse: enquiryReducer,
  couponResponse: couponReducer,
  errorResponse: errorResponse
});

export default rootReducer;
