import { GET_PRODUCT_CAT, ADD_PRODUCT_CAT ,VIEW_PRODUCT_CAT,UPDATE_PRODUCT_CAT,DELETE_PRODUCT_CAT} from "../actions/types";
// import Cookies from "js-cookie";

const initialState = {
  getProductCatList: null,
  addProductCat: null,
  viewProductCat : null,
  updateProductCat : null,
  deleteProductCat: null
};

const productCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_CAT:
      return {
        ...state,
        getProductCatList: action.payload,
      };
      case ADD_PRODUCT_CAT:
      return {
        ...state,
        addProductCat: action.payload,
      };
      case VIEW_PRODUCT_CAT:
        return {
          ...state,
          viewProductCat: action.payload,
        };
        case UPDATE_PRODUCT_CAT:
          return {
            ...state,
            updateProductCat: action.payload,
          };
          case DELETE_PRODUCT_CAT:
            return {
              ...state,
              deleteProductCat : action.payload,
            };
    default:
      return state;
  }
};
export default productCategoryReducer;
